export default {
  'en-US': {
    'button.add': 'Add',
    'button.cart.addToCart': 'Add to Cart',
    'button.cart.addToCartWithQty': 'Add {itemQuantityBeforeCart} to Cart',
    'button.next': 'Next',
    'button.continue': 'Continue',
    'button.update': 'Update',
    'button.apply': 'Apply',
    'button.submit': 'Submit',
    'button.noThanks': 'No, thanks',
    'button.verify': 'Verify',
    'button.startOrdering': 'Start ordering',
    'button.skip': 'Skip',
    'button.yes': 'Yes',
    'button.no': 'No',
    'button.clearCart': 'Clear Cart',
    'button.proceedToCheckout': 'Proceed to Checkout',
    'button.storeClosed': 'Currently store is closed',
    'button.soldOut': 'Sold out',
    'button.back': 'Back',
    'button.remove': 'Remove',
    'button.activate': 'Activate',
    'button.save': 'Save',
    'button.edit': 'Edit',
    'button.delete': 'Delete',
    'button.proceed_to_menu': 'Proceed to menu',
    'button.pay': 'Pay',
    'button.retry': 'Retry',
    'button.hide': 'Hide',
    'button.cancel': 'Cancel',
    'button.select': 'Select',
    'button.clear': 'Clear',
    'button.details': 'Details',
    'order.total.title': 'Cart Total',
    'order.total.discount': 'Discount',
    'order.total.subtotal': 'Subtotal',
    'order.total.tax': 'Tax',
    'order.total.tip': 'Tip',
    'order.total.tipDisclaimer': `Maximum allowed limit for the tip is \${maxAmount}`,
    'order.total.delivery': 'Delivery charge',
    'order.total.total': 'Total',
    'gratuity.title': 'Tip',
    'user.delivery-address.address-out-of-reach': 'Sorry, looks like this address is no longer in our delivery area. Please enter another address.',
    'user.delivery-address.new-address-out-of-reach':
      'Sorry, the address as it was entered is outside of our delivery area. If address should be in delivery area, please enter a more specific address including zipcode, suite #, etc. or use one of the autocomplete options when entering address.',
    'user.delivery-address.get-address-error': 'We could not get your previous addresses, please provide a new one',
    'user.delivery-address.remove-address-error': 'We could not remove this address, please try one more time',
    'user.delivery-address.address-missing-error': 'Please enter an address to continue',
    'gratuity.description': 'Thank you. A tip is much appreciated',
    'gratuity.notification': 'Please select tip amount.',
    'gratuity.notification.error': 'Something went wrong!',
    'gratuity.type.custom_amount': 'Custom Amount',
    'gratuity.type.no_tip': 'No Tip',
    'auth.phoneInput.title': 'Log in or Sign up',
    'auth.phoneInput.subtitle': 'We need your phone to send you a verification code',
    'auth.phoneInput.error': 'Please enter a valid phone number',
    'auth.phoneInput.notes':
      'If you are not registered, we will help you quickly register. | If you are already registered, we will send you a login confirmation code.',
    'auth.registration.title': 'Registration',
    'auth.registration.subtitle': 'The verification code will be sent to {phone}',
    'auth.registration.firstName.label': 'First name',
    'auth.registration.lastName.label': 'Last name',
    'auth.registration.birthday.label': 'Birth date',
    'auth.registration.email.label': 'Email',
    'auth.registration.firstName.info.feedback': 'Ensure only your first name is entered here',
    'auth.userVerification.title': 'Enter your verification code',
    'auth.userVerification.subtitle': 'We have sent your code to {phone}',
    'auth.resend': 'If you did not receive confirmation code, click %here% and we will send it again.',
    'auth.resend.here': 'here',
    'auth.userVerification.resend.done': 'The confirmation code was sent again to your mobile phone and email.',
    'auth.userVerification.resendPhone.done': 'The confirmation code was sent again to your mobile phone.',
    'auth.code.error': 'The confirmation code is wrong. Please enter your confirmation code again.',
    'auth.code.length.error': 'Please enter 5 digits!',
    'auth.userVerification.code.success': 'You have successfully logged in!',
    'auth.mergeSuccess.title': 'Success',
    'auth.mergeSuccess.subtitle': 'We have transferred your loyalty points. You can start spending them on your next order.',
    'auth.mergeUserVerification.title': 'Claim your loyalty account',
    'auth.mergeUserVerification.subtitle':
      'We found a registered loyalty account belonging to this email. In order to claim these points please enter the code sent to your email {email}',
    'auth.mergeUserVerification.resend.done': 'The confirmation code was sent again to your email.',
    'auth.general.error': 'Oops! Something went wrong!',
    'auth.attempts.limit.error': 'Too many requests, please try again later',
    'auth.loggedIn.message': 'Hey {userName},  looks like you are already logged in.',
    'phone.number.error': 'Seems like this phone number cannot accept text messages. Please provide a different number',
    'sent.new.code': 'We sent you a new code',
    'incorrect.phone.verification.code': 'Seems like this code is incorrect',
    'error.too.short': 'Too Short!',
    'error.too.long': 'Too Long!',
    'error.only.letters': 'Sorry, only letters (A-z) are allowed',
    'error.required': 'Please provide {inputLabel}',
    'error.email.format': 'Your email address format is incorrect',
    'error.date.max.limit': 'Maximum date should be {maxDate}',
    'error.addItemToCart': 'Sorry, this item cannot be added to your cart',
    'error.removeItemFromCart': 'Sorry, this item cannot be removed from your cart',
    'error.itemUnavailable': 'Item is unavailable',
    'login.page.title': 'Log in or Sign up',
    'login.page.subtitle': 'We need your phone to send you a verification code',
    'login.phone.input.placeholder': 'Phone number',
    'login.submitBtn': 'Send code',
    'loyalty.info.title': 'Loyalty Information',
    'loyalty.info.load.error': 'Sorry, we are having some issues with loyalty. Please come back soon',
    'loyalty.info.noLoyalty': 'Loyalty is not available for this store.',
    'loyalty.info.notification.error': 'Sorry, seems that something went wrong. Please try again later.',
    'loyalty.checkout.error': 'Sorry, seems that something went wrong. Please try again later or skip this step.',
    'loyalty.checkout.title': 'Loyalty Program',
    'loyalty.disclaimer':
      'Please note that loyalty rewards will not be available if you do not have sufficient points to redeem them or if there is a promotion in the cart that cannot be combined with loyalty rewards.',
    'loyalty.registration.message': 'You have {amount} loyalty {points}. Start redeeming your rewards today!',
    'phoneVerification.title': 'Enter your verification code',
    'phoneVerification.submitBtn': 'Verify',
    'phoneVerification.sendCodeLabel': 'We have sent your code to {phone}',
    'resend.code': 'Resend code',
    'first.name.info.feedback': 'Ensure only your first name is entered here',
    'button.register.submit': 'Save and proceed',
    'about.you': 'About you',
    'first.name': 'First Name',
    'last.name': 'Last Name',
    'email.label': 'Email',
    'birthday.label': 'Birth date',
    'phone.label': 'Phone',
    'allow.contact.you': 'Allow {merchantTitle} to contact you',
    'user.previous-order': 'Previous Orders',
    'order.setup.page.title': 'Setup your order',
    'order.banner.select_dining_option': 'Select dining option',
    'order.setup.page.subtitle': 'Note that some items may be unavailable if time of order is changed',
    'order.setup.page.no_dining_option': 'Note that some items may be unavailable if time of order is changed',
    'order.delayed.error.takeOut': 'Seems that your previously selected Pickup Time is not available anymore, please select new time',
    'order.delayed.error.delivery': 'Seems that your previously selected Delivery Time is not available anymore, please select new time',
    'order.delayed.error.curbside': 'Seems that your previously selected Curbside pickup Time is not available anymore, please select new time',
    'order.delayed.timeError': 'Sorry, the selected time is not available anymore. Please select another time slot',
    'order.delayed.asap.delivery.message': 'ASAP delivery means up to %deliveryTime% minutes',
    'order.delayed.asap.pickup.message': 'ASAP pickup means about %pickupTime% minutes',
    'order.delayed.noTimeslot.message': 'Unfortunately, there is no available time slot for this day, please select another day',
    'page.notification.title': 'Notifications',
    'page.notification.subtitle': 'Receive new offers, promotions and deals through:',
    'page.notification.pushNotification': 'Push Notifications',
    'page.notification.update.user.error': 'Seems that user updating failed. Please try again later',
    'request.general.error': 'Oops something went wrong, please try again',
    'notification.success.addedItemToCart': 'was added to your cart',
    'notification.success.removedItemFromCart': 'was removed from your cart',
    'promo.remove.error': 'Error: can not remove item from your cart.',
    'promo.page.title': 'Deals',
    'promo.page.subtitle': 'Discover our daily and weekly promotions.',
    'promo.status.activated': 'Activated',
    'promo.mustBuy.label': 'Buy',
    'promo.popup.title': 'You have unlocked the promotion {title}',
    'promo.popup.subtitle': 'You can always activate this promo at the Deals page.',
    'payment.title': 'Payment Method',
    'payment.retryText': 'Oops! Something went wrong. Please press the ‘Retry’ button below.',
    'payment.request.error': 'Oops something went wrong, please try again',
    'payment.failedAfterRetry': 'Sorry, we could not load the payment form. Please contact the store representatives.',
    'payment.delayedOrderError': 'Sorry, it seems that selected time is not available anymore.',
    'payment.defaultDelayedPaymentDescription':
      '*please note - for future orders a $1 validation charge will be issued upon your confirmation. This amount will be immediately refunded.',
    'payment.notification.closedStore': 'Sorry, seems that store is currently closed. Please try again later.',
    'payment.notification.unavailableTime': 'Sorry, selected time is not available anymore. Please go back and select another time.',
    'payment.notification.reviewCart': 'Please review your cart before continue.',
    'payment.cart.slot-capacity.error': 'Time slot full, please choose another time.',
    'payment.cart.selected-time-unavailable.error': 'Sorry, the selected time is not available anymore. Please select another time slot.',
    'recommended.title': 'Recommended Items',
    'recommended.subtitle': 'Complete your order with our customer favorites!',
    'cart.promo.error': 'Please enter a valid promo code.',
    'cart.promo.success': 'Promotion was added to your cart',
    'cart.promo.code-invalid': 'Sorry, this Promo Code is not valid.',
    'cart.reward.quantity.error': "Sorry, quantity for this reward wasn't updated.",
    'cart.empty': 'Your cart is empty',
    'cart.cleared': 'Your cart was cleared.',
    'cart.clear.error': 'Error: can not clear your cart.',
    'cart.review.error': 'Please review your cart before continue.',
    'cart.auth.guard': 'You need to be logged in to complete your order.',
    'cart.review-checkout.error': 'Please review your cart before checkout.',
    'cart.item-remove.error': 'Error: can not remove item from your cart.',
    'cart.item-removed': `<strong>{title}</strong> was removed from your cart.`,
    'cart.item.no-properties': 'Item does not have properties.',
    'cart.item-added.success': `<strong>{itemName}</strong> was added to your cart.`,
    'cart.checkout-min-quantity.error': `<strong>{title}</strong> has a limited purchase quantity. Please add minimum {minQuantity} of the same item in order to proceed or remove the reward.`,
    'cart.item-promo-added.success': `Promotion <strong>{title}</strong> was added to your cart.`,
    'cart.quantity.error': 'Sorry, {direction} {quantity} items are allowed.',
    'cart.item-quantity-max.warn': `Sorry, at most {value} item(s) is allowed`,
    'cart.item-quantity-min.warn': `Sorry, at least {value} item(s) is allowed`,
    'cart.quantity-update.error': `We're sorry. Quantity for this item wasn't updated. Try again!`,
    'cart.store-closed.error': `Sorry, but we are closed!
                                Online ordering opens {messageReplacer}.`,
    'cart.ordering-closed.error': `Online ordering is temporarily closed and will return shortly.
                                Please refresh the page for updates. `,
    'cart.has-prep-time.warning': 'Your order will be delayed by {prepTime} because you have selected items that take longer to prepare',
    'cart.quantity-update-item.error': `We're sorry. Quantity for <strong>{title}</strong> wasn't updated. Try again!`,
    'cart.quantity-changed.error':
      'We’re sorry. The <strong>{itemName}</strong> has limited purchase quantity. We have changed your purchase quantity to the {extreme} allowable.',
    'cart.quantity-add.error': `We’re sorry. The <strong>{itemName}</strong> has limited purchase quantity.`,
    'cart.item.add-item-error': 'Error: can not add item to your cart.',
    'cart.item.unavailable': 'Sorry, this item is not available at the moment',
    'cart.order.closed': 'Please note that ordering is closed temporarily.',
    'cart.validation.error': 'Your cart is not valid. Please review your cart before continue.',
    'cart.sync-with-pos.error': 'Your request has failed. Please contact support.',
    'item-modal.notes.title': 'Special Requests',
    'item-modal.notes.disclaimer': 'Changes in this section that require price adjustments cannot be honored (i.e. protein add ons, cheese add ons)',
    'item-modal.notes.placeholder': 'Item notes (i.e. allergies, sauce on the side)...',
    'item-modal.ingredients': 'Ingredients',
    'item-modal.prepTime': 'Takes ≈{prepTime} to prepare',
    'diningOptions.kitchenIsfull': 'Sorry our kitchen is currently full, please try again later',
    'order.confirmation.about.to.order': 'You are about to order from ',
    'order.confirmation.confirm.details': 'Please confirm your details:',
    'order.confirmation.asap': 'Your order will be ready on ',
    'order.confirmation.delivery': 'Your order will be delivered on ',
    'order.confirmation.approx': 'at approx. ',
    'order.confirmation.ready.on': 'Your order will be ready on ',
    'order.confirmation.confirm.delivery': 'Please confirm delivery address:',
    'order.confirmation.confirm.pickup': 'Please confirm pickup address:',
    'order.confirmation.order.details': 'Please confirm your order details:',
    'order.confirmation.table.number': 'Table number:',
    'order.confirmation.disclaimer': 'Disclaimer: ',
    'order.confirmation.special.requests': 'Special Requests',
    'order.confirmation.discount': 'Discount',
    'order.confirmation.item.price': 'Item price',
    'order.confirmation.item.total': 'Item total',
    'order.confirmation.title': 'Order Confirmation',
    'order.confirmation.confirm.message': 'Please confirm { diningOptionName } address: ',
    'order.confirmation.error.unavailableDate.back': 'Selected date is unavailable',
    'order.confirmation.error.unavailableDate.front': 'Something went wrong, please select your time again.',
    'default.delivery.tooltip':
      'Due to recent changes in 2018 NY wage laws, we have added a delivery fee at {storeMerchantTitle} to preserve our quality of service. Thank you for your support.',
    'default.dynamic.delivery.tooltip': 'Delivery fee is dynamic and may change based on delivery address and other rules.',
    'delivery.fee.disclaimer': 'delivery fee disclaimer',
    'delivery.fee.info': 'delivery fee info',
    'edit.user.phone.input.tooltip': 'Sorry, you cannot edit the phone number',
    'edit.user.profile.success.edit': 'Your personal info has been updated',
    'edit.user.profile.delete': 'Delete Account',
    'edit.user.profile.too.many.requests': 'Too many update attempts. Please, try again later',
    'orderReminder.banner.title': 'Order in Progress',
    'orderReminder.banner.arrived': 'I have arrived!',
    'orderReminder.banner.note': 'Please let us know when you have arrived. The order will be carried out to you.',
    'orderReminder.modal.here': 'I’m here',
    'orderReminder.modal.primary': 'Confirm that you have arrived',
    'orderReminder.modal.secondary': 'Please wait us in the car when you arrive. The order will be carried out to you.',
    'orderReminder.modal.success': 'Soon we will carry your order out.',
    'previous.order.page.no.orders': "You don't have previous orders",
    'previous.order.page.product': 'Product',
    'previous.order.page.price': 'Price',
    'previous.order.page.quantity': 'Quantity',
    'previous.order.page.total': 'Total',
    'previous.order.page.discount': 'Discount',
    'previous.order.page.description': 'Description',
    'cart.item.validity.modifications': 'Some of the item modifications are unavailable.',
    'cart.item.validity.unavailable': 'This item is unavailable.Please remove it from the cart.',
    'cart.item.validity.exceeded.max.quantity': 'Item quantity exceeds maximum allowed quantity.',
    'cart.item.validity.subsided.min.quantity': 'Item quantity subsides minimum allowed quantity.',
    'previous.order.page.special.request': 'Special Requests',
    'previous.order.page.applied.promotion.warning': 'Applied promotions will not be added to your next order.',
    'previous.order.page.modal.title': 'Order Preview',
    'giftCard.amount.minMax.limit.warning': 'Sorry, {extremum} custom amount is {value}.',
    'giftCard.addToCart.error': 'Error: can not add gift card to your cart.',
    'giftCard.addToCart.success': 'Gift card was successfully added to your cart',
    'giftCard.updateCard.success': 'Gift card was successfully updated',
    'giftCard.updateCard.error': 'Sorry, an error occurred while gift card updating',
    'giftCard.isGift.label': 'Send this gift card to another person',
    'giftCard.page.buy': 'Purchase an E-Gift card',
    'giftCard.page.edit': 'Edit a Gift Card',
    'giftCard.page.user': 'My Gift Cards',
    'giftCard.page.add': 'Add an Existing Gift Card',
    'giftCard.button.customAmount': 'Custom amount',
    'giftCard.button.deactivate': 'Deactivate',
    'giftCard.button.add': 'Add an Existing Gift Card',
    'giftCard.button.addCard': 'Add Card',
    'giftCard.button.buy': 'Purchase an E-Gift card',
    'giftCard.button.remove.title': 'Remove gift card from your account',
    'giftCard.button.copy.title': 'Copy gift card number',
    'giftCard.amount.error': 'Please choose gift card amount',
    'giftCard.amount.error.minMax': '{extremum} custom amount  is {value}.',
    'giftCard.purchase.disabled.error': 'Gift Card purchase is unavailable. Please contact support',
    'giftCard.catalog.get.error': 'Sorry, an error occurred while getting gift card configuration',
    'giftCard.user.get.error': 'Sorry, an error occurred while getting list of your gift cards',
    'giftCard.user.noGiftCard': "You don't have a gift card yet.",
    'giftCard.remove.confirmation.message': 'Do you want to remove gift card from your account?',
    'giftCard.remove.success': 'Gift card was successfully removed from your account',
    'giftCard.remove.error': 'An error occurred while removing gift card',
    'giftCard.add.card.error.required': 'Please enter gift card number first',
    'giftCard.add.card.error.minLength': 'Gift card number must be longer than or equal to {min} characters',
    'giftCard.add.card.error.maxLength': 'Gift Card number must be shorter than or equal to {max} characters',
    'giftCard.add.card.subtitle': 'Please enter gift card number',
    'giftCard.add.card.notFound.error': 'Gift Card with such number was not found',
    'giftCard.add.card.error': 'Sorry, an error occurred while adding gift card to your account',
    'giftCard.add.card.success': 'Gift Card with {load} balance was successfully added',
    'giftCard.copied': 'Copied!',
    'giftCard.noConfiguration.message': 'Missed gift card configuration, please contact support',
    'giftCard.disabledPurchase.message': 'Gift Card purchase is disabled for this store. Please contact our support for the details',
    'paid.modifiers.notice': 'Paid modifiers are not discounted',
    'combo.notification.addToCart.success': '<strong>{itemTitle}</strong> was added to the cart',
    'combo.notification.addToCart.error': 'There was an error while adding {itemTitle} to the cart',
    'combo.notification.addToCart.availability.error': '<strong>{itemTitle}</strong> is not available anymore',
    'combo.notification.update.success': '<strong>{itemTitle}</strong> was successfully updated',
    'combo.notification.update.error': 'There was an error while updating {itemTitle}',
    'combo.notification.remove.success': 'Item was successfully removed from cart',
    'combo.notification.remove.error': 'There was an error while removing item',
    'combo.notification.validate.error': "You've missed combo items. Please check your combo",
    'combo.notification.get.category.error': 'Sorry, this category is not available at the moment',
    'combo.select.requirements': 'Selected <qty>{selectedQty} of {totalQty} item{plural}</qty>',
    'combo.cart.validity': 'This item is unavailable',
    'item.no.data': 'Sorry, item is unavailable. Please choose another one.',
    'item.notification.soldOut': 'Sorry, this item is temporary unavailable',
    'dinging_option.delivery.delete_address': 'Are you sure you want to delete the address?',
    'dinging_option.delivery.notification': 'Please specify your delivery address',
  },
};
