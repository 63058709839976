import { DiningOptionEnum, PromotionSubTypeEnum, ReceiveMethodEnum } from '@goparrot/common';

import { MENU_PATHS } from '../../../pages/paths';
import type { SubscriptionSettingsType } from '../interface';

export const BIRTHDAY_FORMAT = 'MMMM dd, yyyy';
export const DEFAULT_GIFT_CARD_BG = 'https://res.cloudinary.com/druhu3xb6/image/upload/v1620753521/gift-card-bg.jpg';

export enum StatusTypeEnum {
  ERROR = 'ERROR',
  SUCCESS = 'SUCCESS',
}

export enum AnalyticsActionsEnum {
  ACCOUNT_SUBMIT_PHONE = 'account_submit_phone',
  ACCOUNT_SUBMIT_REGISTRATION_DATA = 'account_submit_registration_data',
  ACCOUNT_SUBMIT_REGISTRATION_DATA_RESULT = 'account_submit_registration_data_result',
  ACCOUNT_RESEND_VALIDATION_CODE = 'account_resend_validation_code',
  ACCOUNT_RESEND_MERGE_USERS_CONFIRMATION_CODE = 'account_resend_merge_users_confirmation_code',
  ACCOUNT_SUBMIT_VALIDATION_CODE = 'account_submit_validation_code',
  ACCOUNT_SUBMIT_VALIDATION_CODE_INVALID = 'account_submit_validation_code_invalid',
  ACCOUNT_SUBMIT_MERGE_USERS_CONFIRMATION_CODE = 'account_submit_merge_users_confirmation_code',
  ACCOUNT_SUBMIT_MERGE_USERS_CONFIRMATION_CODE_INVALID = 'account_submit_merge_users_confirmation_code_invalid',
  ACCOUNT_SKIP_IMPORT_USERS = 'account_skip_import_user',
  ACCOUNT_IMPORTED_USERS = 'account_imported_users',
  ACCOUNT_IMPORTED_USERS_ERROR = 'account_imported_users_error',
  ACCOUNT_SUCCESS_LOGIN = 'account_success_login',
}

export enum TestIdsEnum {
  SUBMIT = 'submit',
  CONTINUE = 'continue',
  PHONE_INPUT = 'phoneInput',
  BACK = 'back',
  FIRST_NAME = 'firstName',
  LAST_NAME = 'lastName',
  EMAIL = 'email',
  BIRTHDAY = 'birthday',
  CONFIRMATION_CODE = 'confirmationCode',
  RESEND_CONFIRMATION_CODE = 'resend',
  SKIP = 'skip',
  NOTIFICATION = 'notification',
}

export const PROMO_ROOT_STEPS = {
  [PromotionSubTypeEnum.PROMOTION]: MENU_PATHS.promos,
  [PromotionSubTypeEnum.PROMOCODE]: MENU_PATHS.cart,
};

export enum LoyaltyPostMessagesEnum {
  MOUNTED = 'MOUNTED',
  DISCOUNT_REWARD_SELECTED = 'DISCOUNT_REWARD_SELECTED',
  ITEM_REWARD_SELECTED = 'ITEM_REWARD_SELECTED',
  CANCEL_DISCOUNT_SUCCESS = 'CANCEL_DISCOUNT_SUCCESS',
  DISCOUNT_REWARD_ADDED = 'DISCOUNT_REWARD_ADDED',
  OPTIONS = 'OPTIONS',
  ERROR = 'ERROR',
  SET_POPUP_ON_LOGIN = 'POPUP_ON_LOGIN',
  USER_BALANCE = 'USER_BALANCE',
  NOTIFICATION = 'NOTIFICATION',
}

export enum DisclaimersEnum {
  FOOTER_PAGE = 'footerPage',
  ORDER_CONFIRMATION = 'orderConfirmationPage',
}

export enum SubscriptionTypesEnum {
  EMAIL = 'EMAIL',
  SMS = 'SMS',
  PUSH_NOTIFICATION = 'PUSH_NOTIFICATION',
}

export const SUBSCRIPTION_DEFAULTS: SubscriptionSettingsType = {
  [SubscriptionTypesEnum.EMAIL]: {
    name: 'Email',
    field: 'isMarketingEmailEnabled',
  },
  [SubscriptionTypesEnum.SMS]: {
    name: 'SMS',
    field: 'isMarketingSMSEnabled',
  },
  [SubscriptionTypesEnum.PUSH_NOTIFICATION]: {
    name: 'pushNotification',
    field: 'isMarketingPushNotificationsEnabled',
  },
};

export const enum PromotionApplicationStateEnum {
  APPLIED = 'APPLIED',
  APPLICABLE = 'APPLICABLE',
  NON_APPLICABLE = 'NON_APPLICABLE',
}

export enum PaymentPostMessagesEnum {
  ON_MOUNT = 'ON_MOUNT',
  MOUNTED = 'MOUNTED',
  SUCCESS = 'success',
  ERROR = 'error',
  NEW_PAYMENT_TYPE = 'NEW_PAYMENT_TYPE',
  ORDER_VALIDITY = 'orderValidity',
  ON_PAY_SUBMIT = 'ON_PAY_SUBMIT',
  SET_PAYMENT_IN_PROCESS = 'SET_PAYMENT_IN_PROCESS',
  SET_AUTHORIZE_NEW_CARD_FORM = 'SET_AUTHORIZE_NEW_CARD_FORM',
}

export enum DeviceSource {
  APP_IOS = 'APP_IOS',
  APP_ANDROID = 'APP_ANDROID',
  WEB_MOBILE = 'WEB_MOBILE',
  WEB_DESKTOP = 'WEB_DESKTOP',
  UNKNOWN_DEVICE = 'UNKNOWN_DEVICE',
}

export const diningOptionRelevantReceiveMetods: Record<DiningOptionEnum, ReceiveMethodEnum> = {
  [DiningOptionEnum.TAKE_OUT]: ReceiveMethodEnum.PICKUP,
  [DiningOptionEnum.CURBSIDE]: ReceiveMethodEnum.PICKUP,
  [DiningOptionEnum.DELIVERY]: ReceiveMethodEnum.DELIVERY,
  [DiningOptionEnum.DINE_IN]: ReceiveMethodEnum.PICKUP,
  [DiningOptionEnum.TO_COUNTER]: ReceiveMethodEnum.DELIVERY,
  [DiningOptionEnum.MULTI_LOCATION]: ReceiveMethodEnum.PICKUP,
};

export enum LocalStorageKeysEnum {
  IS_DINE_IN_SCANNED = 'IS_DINE_IN_SCANNED',
  IS_DINING_OPTION_SELECTED_BY_USER = 'IS_DINING_OPTION_SELECTED_BY_USER',
  IS_DEFAULT_PACKING_INSTRUCTION_SET = 'IS_DEFAULT_PACKING_INSTRUCTION_SET',
  LS_SESSIONS = 'sessions',
}

export const MOBILE_THRESHOLD = 768;
