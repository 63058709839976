import { createIntl, createIntlCache } from 'react-intl';
import messages from '../../messages';

const cache = createIntlCache();

export const intl = createIntl(
  {
    locale: 'en-US',
    messages: messages['en-US'],
  },
  cache,
);
